import React from "react";
import "../css/homePage.css"; // Import the CSS file for styling

function HomePageHeaderComponent() {
  return (
    <header className="home-header">
      <h1 className="home-title">IBUY PHONES</h1>
      <div className="home-button-container">
        <button className="home-button">Prices</button>
        <button className="home-button">Contact</button>
      </div>
    </header>
  );
}

export default HomePageHeaderComponent;
