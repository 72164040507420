import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "../css/homePageGradientHeading.css"; // Import the CSS for the gradient heading

const HomePageGradientHeading = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const el = textRef.current;

    // GSAP animation for infinite scrolling gradient
    gsap.to(el, {
      backgroundPosition: "-200% center", // Scrolls the background to the left
      duration: 4, // Animation duration
      ease: "linear", // Keeps the animation speed constant
      repeat: -1, // Infinite loop
    });
  }, []);

  return (
    <div className="gradient-heading-container">
      <span className="gradient-heading">
        Sell your phone <i ref={textRef}>in minutes.</i>
      </span>
    </div>
  );
};

export default HomePageGradientHeading;
