import React from "react";
import "../css/animatedBackground.css"; // Import the CSS file for the background

const AnimatedBackground = ({ children }) => {
  return (
    <div className="animated-background">
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      {/* Wrap children here to ensure content appears above background */}
      <div className="content-container">{children}</div>
    </div>
  );
};

export default AnimatedBackground;
