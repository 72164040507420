import React, { useEffect, useRef, useState, useMemo } from "react";
import pica from "pica";
import phone1 from "../resources/images/phone1.png";
import phone2 from "../resources/images/phone2.png";
import phone3 from "../resources/images/phone3.png";
import "../css/iphoneFlipAnimation.css";

const IphoneFlippingComponent = () => {
  const images = useMemo(() => [phone1, phone2, phone3], []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [flipped, setFlipped] = useState(false); // State to control the flip animation
  const [containerSize, setContainerSize] = useState({
    width: 300,
    height: 420,
  }); // Default size

  useEffect(() => {
    // Function to update the container size dynamically based on the window size
    const updateContainerSize = () => {
      const width = window.innerWidth * 0.5; // Set container width to 50% of the viewport width (smaller than before)
      const height = width * 0.5; // Maintain a 3:4 aspect ratio
      setContainerSize({ width, height });
    };

    // Update the size initially and whenever the window is resized
    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);

    return () => window.removeEventListener("resize", updateContainerSize);
  }, []);

  useEffect(() => {
    // Preload images to ensure smooth transitions
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    const flipAnimation = () => {
      setFlipped(true); // Trigger flip

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFlipped(false); // Reset flip state for the next image
      }, 600); // Match with the CSS animation duration (0.6s)
    };

    const intervalId = setInterval(flipAnimation, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div
      className="flip-container"
      style={{
        width: `${containerSize.width}px`, // Dynamically set the width
        height: `${containerSize.height}px`, // Dynamically set the height
      }}
    >
      <div
        className={`flipping-image ${flipped ? "flipped" : ""}`} // Apply the flipped class based on state
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: "contain", // Fit the entire image within the container
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat", // Prevent the image from repeating
        }}
      />
    </div>
  );
};

export default IphoneFlippingComponent;
