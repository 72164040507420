import React from "react";
import HomePageHeaderComponent from "../components/HomePageHeaderComponent";
import IphoneIconFlippingComponent from "../components/IphoneFlipComponent";
import AnimatedBackground from "../components/AnimatedBackground";
import HomePageGradientHeading from "../components/HomePageGradientHeading"; // Import the gradient heading component

function HomePage() {
  return (
    <div className="home-container">
      <HomePageHeaderComponent />
      <div className="background-container">
        {/* AnimatedBackground wraps around the other components */}
        <AnimatedBackground>
          {/* Gradient heading above the flipping component */}
          <HomePageGradientHeading />
          <IphoneIconFlippingComponent />
        </AnimatedBackground>
      </div>
      {/* Other components and content for the Home page */}
    </div>
  );
}

export default HomePage;
